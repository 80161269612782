import Dropzone from 'dropzone';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { FilesFor as FilesForService } from 'services/files-for';
import environment from '../../../config/environment.json';

export class UploadFiles {
    static inject = [Notifier, Security, I18n, FilesForService]
    _notifier;
    _security;
    _i18n;
    _filesFor;

    dropzoneEl;
    formAction;
    dropzone = null;
    isAttached = false;
    forType = 'policy';
    fileType = 'policy';

    overwriteForIds = [];

    constructor(notifier, security, i18n, filesFor) {
        this._notifier = notifier;
        this._security = security;
        this._i18n = i18n;
        this._filesFor = filesFor;
    }

    attached() {
        this._initialize();
    }

    detached() {
        if (!this.dropzone) return;
        try {
            this.dropzone.destroy();
            this.dropzone = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    _initialize() {
        this.detached();
        this._setFormAction();
        this._initializeDropzone();
    }

    _setFormAction() {
        const qs = [];
        qs.push(`forType=${encodeURIComponent(this.forType)}`);
        qs.push(`fileType=${encodeURIComponent(this.fileType)}`);
        if (this.overwriteForIds.length) qs.push(`overwriteForIds=${encodeURIComponent(this.overwriteForIds.join(','))}`);
        this.formAction = `${environment.api}/api/file-for/mass-upload-by-filename?${qs.join('&')}`;
        console.log(this.formAction);
        if (this.dropzone) this.dropzone.options.url = this.formAction;
    }

    setOverwriteForId(message) {
        if (message.overwrite) {
            this.overwriteForIds.push(message.forId);
        } else {
            const idx = this.overwriteForIds.findIndex(message.forId);
            if (idx >= 0) this.overwriteForIds.splice(idx, 1);
        }
        this._setFormAction();
    }

    _initializeDropzone() {
        this.dropzone = undefined;

        const me = this;
        let d = new Date();
        const timezoneOffset = d.getTimezoneOffset();
        const settings = this._filesFor.settings[this.forType] || {extensions:['.pdf'],maxFileMb:50,fileTypes:[]};
        this.fileExtensions = settings.extensions.join(', ');
        this.fileSize = settings.maxFileMb;

        this.dropzone = new Dropzone(this.dropzoneEl, {
            url: this.formAction,
            paramName: 'file',
            maxFilesize: settings.maxFileMb, // MB
            headers: { 'Authorization': `Bearer ${this._security.token}`, 'X-LEGACY-TimezoneOffset': timezoneOffset },
            acceptedFiles: settings.extensions.join(','),
            maxFiles: 1,
            timeout: 180000,
            init: function() {
                this.on('addedfile', function(file) {
                    me.processing = true;
                    me.messages = [];
                });
                this.on('success', function(file, response) {
                    this.removeFile(file);
                    me.messages = response;
                    me._notifier.success('file-for-upload-success');
                    me.processing = false;
                    me.overwriteForIds = [];
                });
                this.on('error', function(file, error) {
                    this.removeAllFiles();
                    me.messages = [];
                    me.overwriteForIds = [];
                    me._notifier.error('my-files-upload-failed', true);
                    me.isProcessing = false;
                });
            }
        });
    }
}

